import React from 'react';
import { string, shape } from 'prop-types';
import RecommendationsFeSnapped from '../recommendations-snapped/desktop';
import withTracker from '../with-tracker';

const { useState } = React;

const RecommendationsCarouselDual = ({
  first_item,
  carousel,
  type,
  country,
  deviceType,
}) => {
  const defaultHeight = 430;
  const [maxHeight, setmaxHeight] = useState(defaultHeight);
  const onResize = (height) => setmaxHeight(height);

  const namespace = `ui-${type}`;
  return (
    <section
      className={namespace}
      style={{ '--carousel-dual-height': `${maxHeight}px` }}
    >
      <div className={`${namespace}__first-card`}>
        <RecommendationsFeSnapped
          {...first_item}
          isDualCarouselSingle
          country={country}
          deviceType={deviceType}
        />
      </div>
      <div className={`${namespace}__carousel`}>
        <RecommendationsFeSnapped
          {...carousel}
          isDualCarousel
          onResize={onResize}
          country={country}
          deviceType={deviceType}
        />
      </div>
    </section>
  );
};

RecommendationsCarouselDual.propTypes = {
  first_item: shape({}).isRequired,
  carousel: shape({}).isRequired,
  type: string.isRequired,
  country: shape({}),
  deviceType: string,
};

export default withTracker(RecommendationsCarouselDual);
